<template>
  <div class="resource-section container">
    <ul class="nav nav-pills" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ active: activeThisTab }"
          id="assignments-tab"
          data-bs-toggle="tab"
          data-bs-target="#assignments"
          type="button"
          role="tab"
          aria-controls="assignments"
          aria-selected="false"
        >
          assignments
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ active: !activeThisTab }"
          id="exams-tab"
          data-bs-toggle="tab"
          data-bs-target="#exams"
          type="button"
          role="tab"
          aria-controls="exams"
          aria-selected="false"
        >
          exams
        </button>
      </li>
    </ul>

    <div class="tab-content" id="myTabContent">
      <div class="col-md-4"></div>
      <!-- <div class="filter-row col-md-4">
        <Multiselect
          v-model="programOptions.value"
          v-bind="programOptions"
          @select="fetchAllSubscribedProgramExams(), fetchAllSubscribedProgramAssignments()"
        />
      </div> -->
      <div class="col-md-2"></div>
      <div class="col-md-2"></div>
      <div
        class="tab-pane fade"
        :class="{ 'show active': activeThisTab }"
        id="assignments"
        role="tabpanel"
        aria-labelledby="assignments-tab"
      >
        <div v-if="assignmentLoading" class="loading">Loading...</div>
        <div v-else-if="assignments">
          <ul v-if="assignments" class="list-unstyled assignment-list">
            <li v-for="assignment in assignments" :key="assignment.id">
              <p>{{ assignment.title }}</p>
              <p v-if="assignment.submitted_status == 'DRAFT'">Draft</p>
              <p v-if="assignment.submitted_status == 'PUBLISHED'">Submitted</p>
              <span>{{ convertedTimeForUser(assignment.last_date) }}</span>
              <router-link
                :to="{
                  name: 'CourseAssignment',
                  params: {
                    program_id: assignment.program_id,
                    assignment_id: assignment.id,
                  },
                }"
                class="btn small-cta"
                >Details
              </router-link>
            </li>
          </ul>
          <div class="paging-wrapper py-3">
            <v-pagination
              v-model="assignmentPage"
              :pages="totalAssignmentPages"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="assignmentPaginateUpdateHandler"
              v-if="totalAssignments > 0"
            />
          </div>
          <EmptyList
            v-if="totalAssignments == 0"
            messageText="No assignments found!"
          />
        </div>
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'show active': !activeThisTab }"
        id="exams"
        role="tabpanel"
        aria-labelledby="exams-tab"
      >
        <div v-if="examListLoading" class="loading">Loading...</div>
        <div v-else-if="examLists">
          <ul class="list-unstyled assignment-list exam-list">
            <li v-for="exam in examLists" :key="exam.id">
              <p>{{ exam.title }}</p>
              <span v-if="exam && exam.exam_subjects != null"
                ><span v-if="exam.exam_subjects.subject != null">
                  Subject:
                  {{ exam.exam_subjects[0].subject.subject_title }}</span
                >
                <span v-if="exam.subject_count > 1">
                  + {{ exam.subject_count - 1 }}
                </span>
              </span>
              <span v-if="exam.converted_date"
                >Starts On: {{ exam.converted_date }}</span
              >
              <span>Total Duration: {{ exam.duration }} Min</span>
              <router-link
                :to="{
                  name: 'CourseExam',
                  params: { program_id: exam.program_id, exam_id: exam.id },
                }"
                v-if="exam.status === 'START' && exam.exam_attended === false"
                class="btn small-cta"
                >Attend</router-link
              >
              <router-link
                :to="{
                  name: 'CourseExam',
                  params: { program_id: exam.program_id, exam_id: exam.id },
                }"
                v-else-if="
                  exam.status === 'START' &&
                  exam.exam_attended === true &&
                  exam.parent_exam_id > 0 &&
                  exam.is_reattendable == 1
                "
                class="btn small-cta"
                >Attend</router-link
              >
              <router-link
                :to="{
                  name: 'CourseExam',
                  params: { program_id: exam.program_id, exam_id: exam.id },
                }"
                v-else-if="
                  (exam.status === 'START' && exam.exam_attended === true) ||
                  (exam.status === 'END' && exam.exam_attended === true)
                "
                class="btn small-cta attended"
                >Result</router-link
              >
              <a
                href="javascript:void(0);"
                v-else-if="exam.status === 'PUBLISHED'"
                class="btn small-cta pending"
                >Not Scheduled</a
              >
              <a
                href="javascript:void(0);"
                v-else-if="exam.status === 'END'"
                class="btn small-cta ended"
                >Ended</a
              >
            </li>
          </ul>
          <div class="paging-wrapper py-3">
            <v-pagination
              v-model="examListPage"
              :pages="totalExamListPages"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="examListPaginateUpdateHandler"
              v-if="totalExamLists > 0"
            />
          </div>
          <EmptyList v-if="totalExamLists == 0" messageText="No exams found!" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssignmentService from "@/services/AssignmentService";
import ExamService from "@/services/ExamService";
import EmptyList from "@/components/EmptyList.vue";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Timezone from "@/services/Timezone";
// import Multiselect from "@vueform/multiselect";

export default {
  name: "SubscribedCourseActivity",
  components: {
    EmptyList,
    VPagination,
    // Multiselect,
  },
  data() {
    return {
      activeThisTab: true,
      assignmentLoading: true,
      examListLoading: true,
      // Initial page number for assignments if any
      assignmentPage: 1,
      currentAssignmentPage: 1,
      totalAssignmentPages: 0,
      totalAssignments: 0,
      assignments: [],
      // Initial page number for exam lists if any
      examListPage: 1,
      currentExamListPage: 1,
      totalExamListPages: 0,
      totalExamLists: 0,
      examLists: [],
      programOptions: {
        value: null,
        options: [],
        placeholder: "All Courses",
        canDeselect: false,
        searchable: true,
        loading: true,
      },
    };
  },
  created() {
    this.loadSubscribedPrograms();
    this.fetchAllSubscribedProgramAssignments();
    this.fetchAllSubscribedProgramExams();
    // console.log(this.$route);
  },
  methods: {
    convertedTimeForUser(date) {
      let convertedDay =
        Timezone.convertedTimeZone(date).format("ddd, Do MMM, YYYY");
      let convertedTime = "Last date to submit: " + convertedDay;
      return convertedTime;
    },
    async fetchAllSubscribedProgramAssignments() {
      // let program_id =
      //   this.$route.params.program_id != undefined
      //     ? this.$route.params.program_id
      //     : "";
      // let slug =
      //   this.$route.params.slug != undefined ? this.$route.params.slug : "";
      let params = {
        page: this.currentAssignmentPage,
        program_id: this.programOptions.value,
        // slug: slug,
      };
      // console.log(params);
      await AssignmentService.getAllSubscribedProgramAssignments(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.assignmentLoading = false;
            this.assignments = response.data.assignments.data;
            // console.log(this.assignments);
            // works only when totalAssignmentPages should be the last_page value
            this.totalAssignmentPages = response.data.assignments.last_page;
            this.currentAssignmentPage = response.data.assignments.current_page;
            this.totalAssignments = response.data.assignments.total;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async loadSubscribedPrograms() {
      await AssignmentService.getSubscribedPrograms()
        .then((output) => {
          this.programOptions.loading = false;
          if (output.data.status == "SUCCESS") {
            this.programOptions.options = [];
            let allOption = {
              value: "0",
              label: "All Courses",
            };
            this.programOptions.options.push(allOption);
            let programs = output.data.programs;
            if (programs.length > 0) {
              programs.map((program) => {
                let options = {
                  value: program.id,
                  label: program.program_title,
                };
                this.programOptions.options.push(options);
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async fetchAllSubscribedProgramExams() {
      // let program_id =
      //   this.$route.params.program_id != undefined
      //     ? this.$route.params.program_id
      //     : "";
      // let slug =
      //   this.$route.params.slug != undefined ? this.$route.params.slug : "";
      let params = {
        page: this.currentExamListPage,
        program_id: this.programOptions.value,
        // slug: slug,
      };
      // console.log(params);
      await ExamService.getAllSubscribedProgramExams(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.examLists = response.data.exams.data;
            // this.examLists.exam_subjects = null;
            // console.log(this.examLists.exam_subjects.subject);
            // works only when totalExamListPages should be the last_page value
            this.totalExamListPages = response.data.exams.last_page;
            this.currentExamListPage = response.data.exams.current_page;
            this.totalExamLists = response.data.exams.total;
            this.examListLoading = false;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    assignmentPaginateUpdateHandler(selectedPage) {
      if (this.currentAssignmentPage !== selectedPage) {
        this.currentAssignmentPage = selectedPage;
        this.fetchAllSubscribedProgramAssignments();
      }
    },
    examListPaginateUpdateHandler(selectedPage) {
      if (this.currentExamListPage !== selectedPage) {
        this.currentExamListPage = selectedPage;
        this.fetchAllSubscribedProgramExams();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/style/class-single.scss";
</style>
